* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 340px;
}

a {
  text-decoration: none;
  color: #1a77d2;
}
